<template>
  <section class="section">
    <div class="container">
      <div class="services">
        <span id="services" class="services__hash">Услуги</span>
        <h2 class="title services__title">Услуги</h2>
        <p class="subtitle services__text">
          Cоздаем и внедряем IT-решения для среднего и крупного бизнеса. <br />
          Сложные веб-проекты, CRM-системы, автоматизация управленческого учёта – всё,<br />
          что необходимо успешной компании на современном рынке.
        </p>
        <ul class="services__list grid-border-list">
          <li v-for="(item, index) in servicesList" :key="`service-${index}`" class="services__item grid-border-item">
            <div class="service">
              <component :is="item.icon" class="service__icon" />
              <h3 class="card-title">
                {{ item.title }}
              </h3>
              <p class="card-text">
                {{ item.text }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import code from '~/components/icons/services/code.vue';
import crm from '~/components/icons/services/crm.vue';
import doc from '~/components/icons/services/doc.vue';
import erp from '~/components/icons/services/erp.vue';
import layout from '~/components/icons/services/layout.vue';
import mobile from '~/components/icons/services/mobile.vue';

const servicesList = [
  {
    icon: code,
    title: 'Разработка программного обеспечения',
    text: 'Разрабатываем и внедряем системы для автоматизации бизнеса, создавая программное обеспечение, адаптированное под потребности компании, без изменения её бизнес-процессов.',
  },
  {
    icon: layout,
    title: 'Разработка и поддержка информационных систем',
    text: 'Разрабатываем информационные системы всех масштабов, включая корпоративные веб-сервисы для бизнес-автоматизации и веб-проекты для стартапов.',
  },
  {
    icon: mobile,
    title: 'Разработка мобильных приложений',
    text: 'Разрабатываем уникальные мобильные приложения для iOS и Android, включая анализ требований, серверную часть, UI/UX и техподдержку, учитывая бизнес-процессы вашей компании для увеличения продаж и автоматизации рабочих мест.',
  },
  {
    icon: crm,
    title: 'Разработка CRM-систем',
    text: 'Разрабатываем CRM, адаптированные под бизнес-процессы и потребности заказчика, предоставляя комплексные инструменты для управления продажами, от клиентской базы до финансового учета, чтобы стимулировать рост продаж с учетом специфики вашего бизнеса.',
  },
  {
    icon: erp,
    title: 'Разработка и внедрение ERP-систем',
    text: 'Разрабатываем и внедряем облачные ERP-системы, автоматизируя управление предприятием на основе его бизнес-процессов. Наши аналитики и программисты создают инновационные решения, позволяющие ERP работать на автопилоте, улучшая бизнес-эффективность.',
  },
  {
    icon: doc,
    title: 'Разработка систем документооборота',
    text: 'Создаем удобные и надежные системы автоматизации документооборота, полностью адаптированные под бизнес-процессы вашей компании, без необходимости подстраивать их под программу.',
  },
];
</script>

<style scoped lang="scss">
@import url('~/assets/styles/components/grid-border-list.scss');
.services {
  position: relative;
  &__title {
    text-align: center;
    margin-bottom: 36px;
  }

  &__hash {
    font-size: 0;
    position: absolute;
    left: 0;
    top: -200px;
  }

  &__text {
    color: var(--subtitle-color);
    font-weight: var(--bold);
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto 64px;
    max-width: 1208px;
    width: 100%;
  }

  &__item {
    &::before,
    &::after {
      background-color: #e4e4e4;
    }
  }
}

.service {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__icon {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 1024px) {
  .services {
    &__text {
      font-weight: var(--regular);
      font-size: 20px;
      line-height: 26px;
      max-width: 100%;
    }

    &__hash {
      top: -160px;
    }
  }
}

@media (max-width: 767px) {
  .services {
    &__list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
</style>
