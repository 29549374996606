<template>
  <svg
    width="44"
    height="36"
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12V28C2 31.3137 4.68629 34 8 34H36C39.3137 34 42 31.3137 42 28V12M2 12V8C2 4.68629 4.68629 2 8 2H36C39.3137 2 42 4.68629 42 8V12M2 12H42"
      stroke="#EB7A17"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.8377 18.133H29.124L31.3845 23.6418H31.4985L33.759 18.133H37.0453V27.8589H34.4618V22.2361H34.3858L32.2203 27.7829H30.6626L28.4971 22.1981H28.4211V27.8589H25.8377V18.133Z"
      fill="#EB7A17"
    />
    <path
      d="M16.9048 27.8589V18.133H21.1029C21.8248 18.133 22.4564 18.2644 22.9978 18.5271C23.5392 18.7899 23.9603 19.1683 24.261 19.6621C24.5618 20.156 24.7122 20.7481 24.7122 21.4383C24.7122 22.1348 24.557 22.7221 24.2468 23.2001C23.9397 23.6782 23.5075 24.0391 22.9503 24.2829C22.3963 24.5267 21.7488 24.6486 21.008 24.6486H18.5005V22.597H20.4761C20.7863 22.597 21.0507 22.559 21.2692 22.483C21.4908 22.4039 21.6602 22.2788 21.7773 22.1079C21.8976 21.9369 21.9578 21.7137 21.9578 21.4383C21.9578 21.1597 21.8976 20.9333 21.7773 20.7592C21.6602 20.5819 21.4908 20.4521 21.2692 20.3697C21.0507 20.2843 20.7863 20.2415 20.4761 20.2415H19.5453V27.8589H16.9048ZM22.6036 23.3948L25.0351 27.8589H22.1667L19.7922 23.3948H22.6036Z"
      fill="#EB7A17"
    />
    <path
      d="M15.7069 21.7802H13.0285C13.0095 21.5586 12.9588 21.3575 12.8765 21.1771C12.7974 20.9966 12.6866 20.8415 12.5441 20.7117C12.4048 20.5787 12.2354 20.4774 12.0359 20.4077C11.8365 20.3349 11.6101 20.2985 11.3568 20.2985C10.9136 20.2985 10.5384 20.4062 10.2313 20.6214C9.9274 20.8367 9.69628 21.1454 9.53799 21.5475C9.38285 21.9496 9.30528 22.4324 9.30528 22.9959C9.30528 23.5911 9.38443 24.0898 9.54273 24.4919C9.7042 24.8908 9.9369 25.1915 10.2408 25.3942C10.5448 25.5936 10.9104 25.6934 11.3378 25.6934C11.5816 25.6934 11.8001 25.6633 11.9932 25.6031C12.1863 25.5398 12.3541 25.4496 12.4966 25.3324C12.6391 25.2153 12.7546 25.0744 12.8433 24.9098C12.9351 24.742 12.9968 24.5536 13.0285 24.3446L15.7069 24.3636C15.6753 24.7752 15.5597 25.1947 15.3602 25.6221C15.1608 26.0464 14.8774 26.4389 14.5102 26.7999C14.1461 27.1576 13.6949 27.4457 13.1567 27.6642C12.6185 27.8826 11.9932 27.9919 11.2809 27.9919C10.3881 27.9919 9.58706 27.8003 8.87788 27.4172C8.17186 27.0341 7.61306 26.4706 7.20149 25.7266C6.79307 24.9826 6.58887 24.0724 6.58887 22.9959C6.58887 21.9132 6.79782 21.0014 7.21573 20.2605C7.63364 19.5165 8.19719 18.9545 8.90637 18.5746C9.61555 18.1915 10.407 18 11.2809 18C11.8951 18 12.4602 18.0839 12.9762 18.2517C13.4923 18.4195 13.945 18.6649 14.3345 18.9878C14.7239 19.3076 15.0373 19.7017 15.2748 20.1703C15.5122 20.6389 15.6563 21.1755 15.7069 21.7802Z"
      fill="#EB7A17"
    />
  </svg>
</template>
