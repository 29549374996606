<template>
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112 28V108C112 109.061 111.579 110.078 110.828 110.828C110.078 111.579 109.061 112 108 112H4C2.93913 112 1.92172 111.579 1.17157 110.828C0.421427 110.078 0 109.061 0 108V28H112Z"
      fill="url(#paint0_linear_1_865)"
    />
    <path
      d="M43 84C42.5581 83.9986 42.1291 83.8509 41.78 83.58L23.78 69.58C23.5391 69.393 23.3442 69.1535 23.2101 68.8796C23.0759 68.6058 23.0062 68.3049 23.0062 68C23.0062 67.6951 23.0759 67.3942 23.2101 67.1203C23.3442 66.8465 23.5391 66.6069 23.78 66.42L41.78 52.42C42.2014 52.1343 42.7158 52.02 43.2185 52.1005C43.7212 52.1809 44.1743 52.45 44.4854 52.853C44.7966 53.2559 44.9423 53.7624 44.893 54.2691C44.8437 54.7758 44.603 55.2446 44.22 55.58L28.22 68L44.22 80.42C44.549 80.6753 44.7901 81.0269 44.9096 81.4258C45.0292 81.8247 45.0212 82.251 44.8869 82.6451C44.7526 83.0393 44.4986 83.3817 44.1603 83.6246C43.8221 83.8674 43.4164 83.9987 43 84Z"
      fill="#FE9661"
    />
    <path
      d="M69 84C68.5836 83.9987 68.178 83.8674 67.8397 83.6246C67.5015 83.3817 67.2475 83.0393 67.1131 82.6451C66.9788 82.251 66.9709 81.8247 67.0904 81.4258C67.21 81.0269 67.451 80.6753 67.78 80.42L83.78 68L67.78 55.58C67.397 55.2446 67.1563 54.7758 67.107 54.2691C67.0577 53.7624 67.2035 53.2559 67.5146 52.853C67.8257 52.45 68.2788 52.1809 68.7815 52.1005C69.2842 52.02 69.7987 52.1343 70.22 52.42L88.22 66.42C88.4609 66.6069 88.6559 66.8465 88.79 67.1203C88.9241 67.3942 88.9938 67.6951 88.9938 68C88.9938 68.3049 88.9241 68.6058 88.79 68.8796C88.6559 69.1535 88.4609 69.393 88.22 69.58L70.22 83.58C69.8709 83.8509 69.4419 83.9986 69 84Z"
      fill="#FE9661"
    />
    <path
      d="M51.52 88.4599H51.04C50.53 88.3321 50.0909 88.0086 49.8177 87.5594C49.5445 87.1103 49.4591 86.5716 49.58 86.0599L58.54 49.1199C58.6904 48.6338 59.0204 48.2232 59.4629 47.9719C59.9054 47.7206 60.427 47.6474 60.9216 47.7672C61.4162 47.887 61.8465 48.1908 62.1249 48.6168C62.4033 49.0428 62.5088 49.5589 62.42 50.0599L53.46 86.9399C53.3528 87.3736 53.1035 87.7588 52.7519 88.0343C52.4004 88.3097 51.9667 88.4596 51.52 88.4599Z"
      fill="#FE9661"
    />
    <path
      d="M108 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4V28H112V4C112 2.93913 111.579 1.92172 110.828 1.17157C110.078 0.421427 109.061 0 108 0ZM68 16C68 17.0609 67.5786 18.0783 66.8284 18.8284C66.0783 19.5786 65.0609 20 64 20H12C10.9391 20 9.92172 19.5786 9.17157 18.8284C8.42143 18.0783 8 17.0609 8 16V12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8H64C65.0609 8 66.0783 8.42143 66.8284 9.17157C67.5786 9.92172 68 10.9391 68 12V16ZM78 16C77.6044 16 77.2178 15.8827 76.8889 15.6629C76.56 15.4432 76.3036 15.1308 76.1522 14.7654C76.0009 14.3999 75.9613 13.9978 76.0384 13.6098C76.1156 13.2219 76.3061 12.8655 76.5858 12.5858C76.8655 12.3061 77.2219 12.1156 77.6098 12.0384C77.9978 11.9613 78.3999 12.0009 78.7654 12.1522C79.1308 12.3036 79.4432 12.56 79.6629 12.8889C79.8827 13.2178 80 13.6044 80 14C80 14.5304 79.7893 15.0391 79.4142 15.4142C79.0391 15.7893 78.5304 16 78 16ZM90 16C89.6044 16 89.2178 15.8827 88.8889 15.6629C88.56 15.4432 88.3036 15.1308 88.1522 14.7654C88.0009 14.3999 87.9613 13.9978 88.0384 13.6098C88.1156 13.2219 88.3061 12.8655 88.5858 12.5858C88.8655 12.3061 89.2219 12.1156 89.6098 12.0384C89.9978 11.9613 90.3999 12.0009 90.7654 12.1522C91.1308 12.3036 91.4432 12.56 91.6629 12.8889C91.8827 13.2178 92 13.6044 92 14C92 14.5304 91.7893 15.0391 91.4142 15.4142C91.0391 15.7893 90.5304 16 90 16ZM102 16C101.604 16 101.218 15.8827 100.889 15.6629C100.56 15.4432 100.304 15.1308 100.152 14.7654C100.001 14.3999 99.9613 13.9978 100.038 13.6098C100.116 13.2219 100.306 12.8655 100.586 12.5858C100.865 12.3061 101.222 12.1156 101.61 12.0384C101.998 11.9613 102.4 12.0009 102.765 12.1522C103.131 12.3036 103.443 12.56 103.663 12.8889C103.883 13.2178 104 13.6044 104 14C104 14.5304 103.789 15.0391 103.414 15.4142C103.039 15.7893 102.53 16 102 16Z"
      fill="url(#paint1_linear_1_865)"
    />
    <path
      d="M64 8H12C9.79086 8 8 9.79086 8 12V16C8 18.2091 9.79086 20 12 20H64C66.2091 20 68 18.2091 68 16V12C68 9.79086 66.2091 8 64 8Z"
      fill="#F17B31"
    />
    <path
      d="M78 18C77.2089 18 76.4355 17.7654 75.7777 17.3259C75.1199 16.8864 74.6072 16.2616 74.3045 15.5307C74.0017 14.7998 73.9225 13.9956 74.0769 13.2196C74.2312 12.4437 74.6122 11.731 75.1716 11.1716C75.731 10.6122 76.4437 10.2312 77.2196 10.0769C77.9956 9.92252 78.7998 10.0017 79.5307 10.3045C80.2616 10.6072 80.8864 11.1199 81.3259 11.7777C81.7654 12.4355 82 13.2089 82 14C82 15.0609 81.5786 16.0783 80.8284 16.8284C80.0783 17.5786 79.0609 18 78 18Z"
      fill="#F0F7FC"
    />
    <path
      d="M90 18C89.2089 18 88.4355 17.7654 87.7777 17.3259C87.1199 16.8864 86.6072 16.2616 86.3045 15.5307C86.0017 14.7998 85.9225 13.9956 86.0769 13.2196C86.2312 12.4437 86.6122 11.731 87.1716 11.1716C87.731 10.6122 88.4437 10.2312 89.2196 10.0769C89.9956 9.92252 90.7998 10.0017 91.5307 10.3045C92.2616 10.6072 92.8864 11.1199 93.3259 11.7777C93.7654 12.4355 94 13.2089 94 14C94 15.0609 93.5786 16.0783 92.8284 16.8284C92.0783 17.5786 91.0609 18 90 18Z"
      fill="#F0F7FC"
    />
    <path
      d="M102 18C101.209 18 100.436 17.7654 99.7777 17.3259C99.1199 16.8864 98.6072 16.2616 98.3045 15.5307C98.0017 14.7998 97.9225 13.9956 98.0769 13.2196C98.2312 12.4437 98.6122 11.731 99.1716 11.1716C99.731 10.6122 100.444 10.2312 101.22 10.0769C101.996 9.92252 102.8 10.0017 103.531 10.3045C104.262 10.6072 104.886 11.1199 105.326 11.7777C105.765 12.4355 106 13.2089 106 14C106 15.0609 105.579 16.0783 104.828 16.8284C104.078 17.5786 103.061 18 102 18Z"
      fill="#F0F7FC"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_865"
        x1="0"
        y1="70"
        x2="112"
        y2="70"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5DFD3" />
        <stop offset="1" stop-color="#FFF2EF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_865"
        x1="21.58"
        y1="49.58"
        x2="90.42"
        y2="-19.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
    </defs>
  </svg>
</template>
