<template>
  <section class="section light-bg">
    <div class="container">
      <div class="advantages">
        <h2 class="title advantages__title">TERPO Software</h2>
        <ul class="advantages__list grid-border-list">
          <li v-for="(item, index) in advantagesList" :key="`advantage-${index}`" class="advantages__item grid-border-item">
            <div class="advantages-card">
              <component :is="item.icon" class="advantages-card__icon" />
              <p class="advantages-card__text card-title">
                <span>{{ item.text }}</span>
                <span v-if="item.textBottom">
                  <br />
                  {{ item.textBottom }}
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import coding from '~/components/icons/advantages/coding.vue';
import computerNetwork from '~/components/icons/advantages/computer-network.vue';
import networking from '~/components/icons/advantages/networking.vue';
import price from '~/components/icons/advantages/price.vue';
import programming from '~/components/icons/advantages/programming.vue';
import web from '~/components/icons/advantages/web.vue';

const advantagesList = [
  {
    icon: coding,
    text: 'Готовые',
    textBottom: 'платформы TERPO',
  },
  {
    icon: programming,
    text: 'Разработка программ',
    textBottom: 'и приложений',
  },
  {
    icon: price,
    text: 'Точный расчет цены разработки',
  },
  {
    icon: computerNetwork,
    text: 'Возможность удаленного сотрудничества',
  },
  {
    icon: networking,
    text: 'ИТ-консалтинг ',
    textBottom: 'и аутсорсинг',
  },
  {
    icon: web,
    text: 'Работа с вашими собственными разработками',
  },
];
</script>

<style scoped lang="scss">
@import url('~/assets/styles/components/grid-border-list.scss');
.advantages {
  &__title {
    margin-bottom: 48px;
  }

  &__item {
    &::before,
    &::after {
      background-color: var(--border-color);
    }
  }
}

.advantages-card {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__icon {
    width: 128px;
    height: 128px;
  }
}

@media (max-width: 1024px) {
  .advantages {
    &__title {
      display: none;
    }
  }

  .advantages-card {
    align-items: center;
    text-align: center;

    &__icon {
      width: 96px;
      height: 96px;
    }
  }
}

@media (max-width: 1024px) {
  .advantages-card {
    &__icon {
      width: 64px;
      height: 64px;
    }
  }
}
</style>
