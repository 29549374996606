<template>
  <svg
    width="32"
    height="41"
    viewBox="0 0 32 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.9957 6.90625C31.9957 3.59225 29.3097 0.90625 25.9957 0.90625H5.99567C2.68167 0.90625 -0.0043335 3.59225 -0.0043335 6.90625V34.9062C-0.0043335 38.2202 2.68167 40.9062 5.99567 40.9062H25.9957C29.3097 40.9062 31.9957 38.2202 31.9957 34.9062V6.90625ZM27.9957 6.90625V32.9062C24.3597 32.9062 7.63167 32.9062 3.99567 32.9062V6.90625C3.99567 5.80225 4.89167 4.90625 5.99567 4.90625H25.9957C27.0997 4.90625 27.9957 5.80225 27.9957 6.90625ZM17.9957 36.9062C17.9957 38.0102 17.0997 38.9062 15.9957 38.9062C14.8917 38.9062 13.9957 38.0102 13.9957 36.9062C13.9957 35.8023 14.8917 34.9062 15.9957 34.9062C17.0997 34.9062 17.9957 35.8023 17.9957 36.9062Z"
      fill="#EB7A17"
    />
  </svg>
</template>
