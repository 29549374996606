<template>
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.32397 0.859619C2.82397 0.859619 0.0119629 3.67061 0.0119629 7.17201V30.5476C0.0119629 34.0476 2.82397 36.8596 6.32397 36.8596H14.012H29.7C33.2 36.8596 36.012 34.0496 36.012 30.5476V7.17201C36.012 3.67061 33.2 0.859619 29.7 0.859619H6.32397ZM6.32397 4.85962H29.7C30.992 4.85962 32.012 5.87981 32.012 7.17201V12.8596H14.012H4.01196V7.17201C4.01196 5.87981 5.03197 4.85962 6.32397 4.85962ZM8.01196 6.85962C6.90796 6.85962 6.01196 7.75502 6.01196 8.85962C6.01196 9.96422 6.90796 10.8596 8.01196 10.8596C9.11596 10.8596 10.012 9.96422 10.012 8.85962C10.012 7.75502 9.11596 6.85962 8.01196 6.85962ZM14.012 6.85962C12.908 6.85962 12.012 7.75502 12.012 8.85962C12.012 9.96422 12.908 10.8596 14.012 10.8596C15.116 10.8596 16.012 9.96422 16.012 8.85962C16.012 7.75502 15.116 6.85962 14.012 6.85962ZM4.01196 16.8596H12.012V32.8596H6.32397C5.03197 32.8596 4.01196 31.8396 4.01196 30.5476V16.8596ZM16.012 16.8596H32.012V30.5476C32.012 31.8396 30.992 32.8596 29.7 32.8596H16.012V16.8596Z"
      fill="#EB7A17"
    />
  </svg>
</template>
