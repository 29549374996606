<template>
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.4688 10H24.5312C19.8196 10 16 13.5745 16 17.9839V112.016C16 116.425 19.8196 120 24.5312 120H98.4688C103.18 120 107 116.425 107 112.016V17.9839C107 13.5745 103.18 10 98.4688 10Z"
      fill="url(#paint0_linear_1_962)"
    />
    <path
      d="M95.4444 96H54.5556C53.1442 96 52 96.8954 52 98V104C52 105.105 53.1442 106 54.5556 106H95.4444C96.8558 106 98 105.105 98 104V98C98 96.8954 96.8558 96 95.4444 96Z"
      fill="url(#paint1_linear_1_962)"
    />
    <path
      d="M44.8889 96H27.1111C26.4975 96 26 96.8954 26 98V104C26 105.105 26.4975 106 27.1111 106H44.8889C45.5025 106 46 105.105 46 104V98C46 96.8954 45.5025 96 44.8889 96Z"
      fill="url(#paint2_linear_1_962)"
    />
    <path
      d="M44.8889 78H27.1111C26.4975 78 26 78.8954 26 80V86C26 87.1046 26.4975 88 27.1111 88H44.8889C45.5025 88 46 87.1046 46 86V80C46 78.8954 45.5025 78 44.8889 78Z"
      fill="url(#paint3_linear_1_962)"
    />
    <path
      d="M44.8889 60H27.1111C26.4975 60 26 60.8954 26 62V68C26 69.1046 26.4975 70 27.1111 70H44.8889C45.5025 70 46 69.1046 46 68V62C46 60.8954 45.5025 60 44.8889 60Z"
      fill="url(#paint4_linear_1_962)"
    />
    <path
      d="M94 22H30C27.7909 22 26 24.6863 26 28V46C26 49.3137 27.7909 52 30 52H94C96.2091 52 98 49.3137 98 46V28C98 24.6863 96.2091 22 94 22Z"
      fill="url(#paint5_linear_1_962)"
    />
    <path
      d="M70.8889 78H53.1111C52.4975 78 52 78.8954 52 80V86C52 87.1046 52.4975 88 53.1111 88H70.8889C71.5025 88 72 87.1046 72 86V80C72 78.8954 71.5025 78 70.8889 78Z"
      fill="url(#paint6_linear_1_962)"
    />
    <path
      d="M70.8889 60H53.1111C52.4975 60 52 60.8954 52 62V68C52 69.1046 52.4975 70 53.1111 70H70.8889C71.5025 70 72 69.1046 72 68V62C72 60.8954 71.5025 60 70.8889 60Z"
      fill="url(#paint7_linear_1_962)"
    />
    <path
      d="M96.8889 78H79.1111C78.4975 78 78 78.8954 78 80V86C78 87.1046 78.4975 88 79.1111 88H96.8889C97.5025 88 98 87.1046 98 86V80C98 78.8954 97.5025 78 96.8889 78Z"
      fill="url(#paint8_linear_1_962)"
    />
    <path
      d="M96.8889 60H79.1111C78.4975 60 78 60.8954 78 62V68C78 69.1046 78.4975 70 79.1111 70H96.8889C97.5025 70 98 69.1046 98 68V62C98 60.8954 97.5025 60 96.8889 60Z"
      fill="#FFAE68"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_962"
        x1="33.5338"
        y1="204.779"
        x2="140.782"
        y2="182.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_962"
        x1="75"
        y1="106"
        x2="75"
        y2="96"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_962"
        x1="36"
        y1="106"
        x2="36"
        y2="96"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_962"
        x1="36"
        y1="88"
        x2="36"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_962"
        x1="36"
        y1="70"
        x2="36"
        y2="60"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_962"
        x1="26"
        y1="37"
        x2="98"
        y2="37"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5DFD3" />
        <stop offset="1" stop-color="#FFF2EF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_962"
        x1="62"
        y1="88"
        x2="62"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1_962"
        x1="62"
        y1="70"
        x2="62"
        y2="60"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1_962"
        x1="88"
        y1="88"
        x2="88"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
    </defs>
  </svg>
</template>
