<template>
  <svg
    width="44"
    height="36"
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12V28C2 31.3137 4.68629 34 8 34H36C39.3137 34 42 31.3137 42 28V12M2 12V8C2 4.68629 4.68629 2 8 2H36C39.3137 2 42 4.68629 42 8V12M2 12H42"
      stroke="#EB7A17"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.8788 27V17.2741H31.0769C31.7987 17.2741 32.4303 17.4166 32.9717 17.7015C33.5131 17.9865 33.9342 18.387 34.2349 18.903C34.5357 19.4191 34.6861 20.0222 34.6861 20.7124C34.6861 21.4089 34.5309 22.012 34.2207 22.5217C33.9136 23.0315 33.4814 23.424 32.9242 23.6995C32.3702 23.9749 31.7227 24.1126 30.9819 24.1126H28.4744V22.0611H30.45C30.7603 22.0611 31.0246 22.0073 31.2431 21.8996C31.4647 21.7888 31.6341 21.6321 31.7512 21.4295C31.8715 21.2268 31.9317 20.9878 31.9317 20.7124C31.9317 20.4338 31.8715 20.1963 31.7512 20C31.6341 19.8006 31.4647 19.6486 31.2431 19.5441C31.0246 19.4365 30.7603 19.3827 30.45 19.3827H29.5192V27H26.8788Z"
      fill="#EB7A17"
    />
    <path
      d="M17.9459 27V17.2741H22.144C22.8659 17.2741 23.4975 17.4055 24.0389 17.6683C24.5803 17.9311 25.0013 18.3094 25.3021 18.8033C25.6029 19.2972 25.7532 19.8892 25.7532 20.5794C25.7532 21.2759 25.5981 21.8632 25.2879 22.3413C24.9807 22.8193 24.5486 23.1803 23.9914 23.424C23.4373 23.6678 22.7899 23.7897 22.049 23.7897H19.5416V21.7382H21.5172C21.8274 21.7382 22.0918 21.7002 22.3102 21.6242C22.5319 21.545 22.7012 21.42 22.8184 21.249C22.9387 21.078 22.9988 20.8548 22.9988 20.5794C22.9988 20.3008 22.9387 20.0744 22.8184 19.9003C22.7012 19.723 22.5319 19.5932 22.3102 19.5109C22.0918 19.4254 21.8274 19.3827 21.5172 19.3827H20.5864V27H17.9459ZM23.6447 22.536L26.0762 27H23.2078L20.8333 22.536H23.6447Z"
      fill="#EB7A17"
    />
    <path
      d="M9.62689 27V17.2741H16.6364V19.4017H12.2673V21.0733H16.2755V23.2008H12.2673V24.8725H16.6174V27H9.62689Z"
      fill="#EB7A17"
    />
  </svg>
</template>
