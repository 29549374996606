<template>
  <section class="section">
    <div class="container">
      <div class="clip">
        <div class="clip__video">
          <iframe
            width="100%"
            height="100%"
            src="https://rutube.ru/play/embed/a68f27897a1216accc8f4e707c93f330/"
            frameBorder="0"
            allow="clipboard-write;"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
        <div class="clip__text">
          TERPO software - аккредитованная российская IT- компания. С момента основания в 2021 году зарекомендовала себя как надежный
          партнер на рынке IT-услуг. Компания продолжает уверенный рост и стремится к превосходству в каждом проекте.
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import url('~/assets/styles/components/grid-border-list.scss');

.clip {
  display: flex;
  gap: 40px;
  align-items: center;

  &__video {
    width: 100%;
    min-width: 600px;
    height: 400px;
    background-image: url('/img/bg/clip.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 32px;

    iframe {
      border-radius: 32px;
    }
  }

  &__text {
    color: var(--subtitle-color);
    font-weight: var(--bold);
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    max-width: 1208px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .clip {
    flex-direction: column-reverse;

    &__video {
      height: 400px;
    }
  }
}

@media (max-width: 767px) {
  .clip {
    &__video {
      max-width: 100%;
      min-width: 100%;
      height: auto;
    }
  }
}
</style>
<script setup lang="ts"></script>
