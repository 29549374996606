<template>
  <svg
    width="112"
    height="120"
    viewBox="0 0 112 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112 76V84C112 87.1826 110.736 90.2348 108.485 92.4853C106.235 94.7357 103.183 96 100 96H12C8.8174 96 5.76516 94.7357 3.51472 92.4853C1.26428 90.2348 0 87.1826 0 84V76H112Z"
      fill="url(#paint0_linear_1_976)"
    />
    <path
      d="M112 20V76H1.23957e-05V20C-0.0400105 17.1354 0.946136 14.351 2.78019 12.1501C4.61423 9.94929 7.17516 8.4772 10 8H102C104.825 8.4772 107.386 9.94929 109.22 12.1501C111.054 14.351 112.04 17.1354 112 20Z"
      fill="url(#paint1_linear_1_976)"
    />
    <path
      d="M112 20V76H1.23957e-05V20C-0.0400105 17.1354 0.946136 14.351 2.78019 12.1501C4.61423 9.94929 7.17516 8.4772 10 8H102C104.825 8.4772 107.386 9.94929 109.22 12.1501C111.054 14.351 112.04 17.1354 112 20Z"
      fill="url(#paint2_linear_1_976)"
    />
    <path d="M76.58 119.9H35.42L43.66 95.9H68.34L76.58 119.9Z" fill="#F17B31" />
    <path
      d="M80 120H32C31.4696 120 30.9609 119.789 30.5858 119.414C30.2107 119.039 30 118.53 30 118C30 117.47 30.2107 116.961 30.5858 116.586C30.9609 116.211 31.4696 116 32 116H80C80.5304 116 81.0391 116.211 81.4142 116.586C81.7893 116.961 82 117.47 82 118C82 118.53 81.7893 119.039 81.4142 119.414C81.0391 119.789 80.5304 120 80 120Z"
      fill="url(#paint3_linear_1_976)"
    />
    <path
      d="M58 88H54C53.4696 88 52.9609 87.7893 52.5858 87.4142C52.2107 87.0391 52 86.5304 52 86C52 85.4696 52.2107 84.9609 52.5858 84.5858C52.9609 84.2107 53.4696 84 54 84H58C58.5304 84 59.0391 84.2107 59.4142 84.5858C59.7893 84.9609 60 85.4696 60 86C60 86.5304 59.7893 87.0391 59.4142 87.4142C59.0391 87.7893 58.5304 88 58 88Z"
      fill="url(#paint4_linear_1_976)"
    />
    <path
      d="M102 4V20H10V4C10 2.93913 10.4214 1.92172 11.1716 1.17157C11.9217 0.421427 12.9391 0 14 0H98C99.0609 0 100.078 0.421427 100.828 1.17157C101.579 1.92172 102 2.93913 102 4Z"
      fill="url(#paint5_linear_1_976)"
    />
    <path
      d="M102 20V62C102 63.0609 101.579 64.0783 100.828 64.8284C100.078 65.5786 99.0609 66 98 66H14C12.9391 66 11.9217 65.5786 11.1716 64.8284C10.4214 64.0783 10 63.0609 10 62V20H102Z"
      fill="#F0F7FC"
    />
    <path
      d="M90 14C89.2089 14 88.4355 13.7654 87.7777 13.3259C87.1199 12.8864 86.6072 12.2616 86.3045 11.5307C86.0017 10.7998 85.9225 9.99556 86.0769 9.21964C86.2312 8.44372 86.6122 7.73098 87.1716 7.17157C87.731 6.61216 88.4437 6.2312 89.2197 6.07686C89.9956 5.92252 90.7998 6.00173 91.5307 6.30448C92.2617 6.60723 92.8864 7.11993 93.3259 7.77772C93.7654 8.43552 94 9.20888 94 10C94 11.0609 93.5786 12.0783 92.8284 12.8284C92.0783 13.5786 91.0609 14 90 14Z"
      fill="#F0F7FC"
    />
    <path
      d="M78 14C77.2089 14 76.4355 13.7654 75.7777 13.3259C75.1199 12.8864 74.6072 12.2616 74.3045 11.5307C74.0017 10.7998 73.9225 9.99556 74.0769 9.21964C74.2312 8.44372 74.6122 7.73098 75.1716 7.17157C75.731 6.61216 76.4437 6.2312 77.2196 6.07686C77.9956 5.92252 78.7998 6.00173 79.5307 6.30448C80.2616 6.60723 80.8864 7.11993 81.3259 7.77772C81.7654 8.43552 82 9.20888 82 10C82 11.0609 81.5786 12.0783 80.8284 12.8284C80.0783 13.5786 79.0609 14 78 14Z"
      fill="#F0F7FC"
    />
    <path
      d="M66 14C65.2089 14 64.4355 13.7654 63.7777 13.3259C63.1199 12.8864 62.6072 12.2616 62.3045 11.5307C62.0017 10.7998 61.9225 9.99556 62.0769 9.21964C62.2312 8.44372 62.6122 7.73098 63.1716 7.17157C63.731 6.61216 64.4437 6.2312 65.2196 6.07686C65.9956 5.92252 66.7998 6.00173 67.5307 6.30448C68.2616 6.60723 68.8864 7.11993 69.3259 7.77772C69.7654 8.43552 70 9.20888 70 10C70 11.0609 69.5786 12.0783 68.8284 12.8284C68.0783 13.5786 67.0609 14 66 14Z"
      fill="#F0F7FC"
    />
    <path
      d="M54 12H20C19.4696 12 18.9609 11.7893 18.5858 11.4142C18.2107 11.0391 18 10.5304 18 10C18 9.46957 18.2107 8.96086 18.5858 8.58579C18.9609 8.21071 19.4696 8 20 8H54C54.5304 8 55.0391 8.21071 55.4142 8.58579C55.7893 8.96086 56 9.46957 56 10C56 10.5304 55.7893 11.0391 55.4142 11.4142C55.0391 11.7893 54.5304 12 54 12Z"
      fill="#F17B31"
    />
    <path
      d="M22 51.9999C21.6706 51.9982 21.3467 51.9152 21.0571 51.7582C20.7675 51.6012 20.5212 51.3751 20.34 51.0999L16.34 45.0999C16.125 44.7734 16.0103 44.3909 16.0103 43.9999C16.0103 43.6089 16.125 43.2265 16.34 42.8999L20.34 36.8999C20.6526 36.5119 21.0988 36.2545 21.5913 36.1781C22.0837 36.1018 22.5869 36.2121 23.0023 36.4874C23.4177 36.7626 23.7154 37.1831 23.8371 37.6663C23.9587 38.1495 23.8956 38.6608 23.66 39.0999L20.4 43.9999L23.66 48.8999C23.9495 49.3409 24.053 49.8783 23.9482 50.3952C23.8433 50.9121 23.5384 51.3667 23.1 51.6599C22.7779 51.8855 22.3932 52.0044 22 51.9999Z"
      fill="#FE9661"
    />
    <path
      d="M38 51.9999C37.6068 52.0044 37.2221 51.8855 36.9 51.6599C36.4616 51.3667 36.1567 50.9121 36.0519 50.3952C35.947 49.8783 36.0505 49.3409 36.34 48.8999L39.6 43.9999L36.34 39.0999C36.1654 38.8832 36.038 38.6325 35.9659 38.3637C35.8938 38.0949 35.8785 37.8141 35.9211 37.5391C35.9638 37.2641 36.0633 37.001 36.2134 36.7666C36.3635 36.5323 36.5608 36.3319 36.7928 36.1782C37.0248 36.0244 37.2863 35.9208 37.5606 35.8739C37.8349 35.827 38.1159 35.8379 38.3858 35.9058C38.6557 35.9738 38.9084 36.0973 39.1278 36.2685C39.3472 36.4396 39.5285 36.6547 39.66 36.8999L43.66 42.8999C43.8751 43.2265 43.9897 43.6089 43.9897 43.9999C43.9897 44.391 43.8751 44.7734 43.66 45.0999L39.66 51.0999C39.4788 51.3751 39.2325 51.6012 38.9429 51.7582C38.6533 51.9152 38.3294 51.9982 38 51.9999Z"
      fill="#FE9661"
    />
    <path
      d="M28 53.74C27.8339 53.7595 27.6661 53.7595 27.5 53.74C27.2331 53.6821 26.9808 53.57 26.7588 53.4109C26.5368 53.2517 26.3498 53.0487 26.2092 52.8145C26.0687 52.5802 25.9776 52.3197 25.9416 52.0489C25.9056 51.7781 25.9255 51.5028 26 51.24L30 35.76C30.1326 35.2455 30.4642 34.8047 30.9218 34.5347C31.3794 34.2646 31.9255 34.1874 32.44 34.32C32.9545 34.4526 33.3953 34.7842 33.6654 35.2418C33.9354 35.6994 34.0126 36.2455 33.88 36.76L29.88 52.24C29.7717 52.6601 29.5298 53.0336 29.1907 53.3042C28.8516 53.5747 28.4337 53.7277 28 53.74Z"
      fill="#FE9661"
    />
    <path
      d="M72 58H56C54.9409 57.9956 53.9267 57.5712 53.18 56.82L60.54 49.46C60.9579 49.0405 61.4636 48.7189 62.0208 48.5186C62.578 48.3182 63.1728 48.244 63.7621 48.3014C64.3514 48.3588 64.9207 48.5464 65.4287 48.8505C65.9368 49.1546 66.371 49.5677 66.7 50.0601L68 52L72 58Z"
      fill="#F17B31"
    />
    <path
      d="M94 34V54C94.005 54.5083 93.9085 55.0124 93.7162 55.4829C93.5239 55.9534 93.2396 56.3808 92.88 56.74L81.08 43.48C80.6976 43.0605 80.2303 42.7273 79.7091 42.5024C79.188 42.2774 78.625 42.166 78.0574 42.1755C77.4899 42.1849 76.9309 42.3151 76.4175 42.5572C75.9042 42.7994 75.4482 43.148 75.08 43.58L68 52L66.7 50C66.371 49.5077 65.9368 49.0946 65.4287 48.7904C64.9207 48.4863 64.3514 48.2988 63.7621 48.2414C63.1727 48.184 62.578 48.2581 62.0208 48.4585C61.4636 48.6589 60.9578 48.9804 60.54 49.4L53.18 56.76C52.4433 56.0281 52.0201 55.0383 52 54V34C52 32.9391 52.4214 31.9217 53.1716 31.1716C53.9217 30.4214 54.9391 30 56 30H90C91.0609 30 92.0783 30.4214 92.8284 31.1716C93.5786 31.9217 94 32.9391 94 34Z"
      fill="url(#paint6_linear_1_976)"
    />
    <path
      d="M92.88 56.74C92.5152 57.1403 92.0703 57.4594 91.5741 57.6765C91.0779 57.8936 90.5416 58.0038 90 58H72L68 52L75.02 43.58C75.3882 43.1481 75.8442 42.7994 76.3575 42.5572C76.8709 42.3151 77.4299 42.1849 77.9974 42.1755C78.565 42.166 79.128 42.2775 79.6491 42.5024C80.1703 42.7273 80.6376 43.0606 81.02 43.48L92.88 56.74Z"
      fill="#F17B31"
    />
    <path
      d="M64 44C63.2089 44 62.4355 43.7654 61.7777 43.3259C61.1199 42.8864 60.6072 42.2616 60.3045 41.5307C60.0017 40.7998 59.9225 39.9956 60.0769 39.2196C60.2312 38.4437 60.6122 37.731 61.1716 37.1716C61.731 36.6122 62.4437 36.2312 63.2196 36.0769C63.9956 35.9225 64.7998 36.0017 65.5307 36.3045C66.2616 36.6072 66.8864 37.1199 67.3259 37.7777C67.7654 38.4355 68 39.2089 68 40C68 41.0609 67.5786 42.0783 66.8284 42.8284C66.0783 43.5786 65.0609 44 64 44Z"
      fill="#F0F7FC"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_976"
        x1="21.58"
        y1="111.414"
        x2="68.0979"
        y2="46.3081"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_976"
        x1="56"
        y1="75.92"
        x2="56"
        y2="8.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_976"
        x1="-0.00115967"
        y1="42"
        x2="112.001"
        y2="42"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5DFD3" />
        <stop offset="1" stop-color="#FFF2EF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_976"
        x1="40.8333"
        y1="122"
        x2="57.4404"
        y2="94.9421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FFCEA1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_976"
        x1="52"
        y1="85.92"
        x2="60"
        y2="85.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D3E6F5" />
        <stop offset="1" stop-color="#F0F7FC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_976"
        x1="27.7264"
        y1="35.4143"
        x2="76.4235"
        y2="-20.571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_976"
        x1="60.0925"
        y1="77.3843"
        x2="104.825"
        y2="59.8375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
    </defs>
  </svg>
</template>
