<template>
  <svg
    width="33"
    height="40"
    viewBox="0 0 33 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18057 -0.00231934C3.27857 -0.00231934 0.0565796 2.95768 0.0565796 6.68569V33.3098C0.0565796 37.0378 3.27857 39.9978 7.18057 39.9978H24.9326C28.8346 39.9978 32.0566 37.0378 32.0566 33.3098C32.0566 32.0078 32.0566 17.7137 32.0566 13.9977C32.0566 13.9777 32.0566 13.9557 32.0566 13.9357C32.0566 6.80969 25.1786 -0.00231934 18.0566 -0.00231934C9.62858 -0.00231934 9.60857 -0.00231934 7.18057 -0.00231934ZM7.18057 3.99769C9.16257 3.99769 11.4046 3.99769 16.0566 3.99769V9.30972C16.0566 13.0377 19.2786 15.9977 23.1806 15.9977H28.0566C28.0566 21.0717 28.0566 32.1418 28.0566 33.3098C28.0566 34.7378 26.7086 35.9978 24.9326 35.9978H7.18057C5.40457 35.9978 4.05658 34.7378 4.05658 33.3098V6.68569C4.05658 5.25769 5.40457 3.99769 7.18057 3.99769ZM20.0566 4.24769C23.6006 5.1877 26.8886 8.46371 27.8066 11.9977H23.1806C21.4026 11.9977 20.0566 10.7377 20.0566 9.30972V4.24769ZM10.0566 11.9977C8.95258 11.9977 8.05658 12.8937 8.05658 13.9977C8.05658 15.1017 8.95258 15.9977 10.0566 15.9977H12.0566C13.1606 15.9977 14.0566 15.1017 14.0566 13.9977C14.0566 12.8937 13.1606 11.9977 12.0566 11.9977H10.0566ZM10.0566 19.9977C8.95258 19.9977 8.05658 20.8937 8.05658 21.9977C8.05658 23.1018 8.95258 23.9978 10.0566 23.9978H22.0566C23.1606 23.9978 24.0566 23.1018 24.0566 21.9977C24.0566 20.8937 23.1606 19.9977 22.0566 19.9977H10.0566ZM10.0566 27.9978C8.95258 27.9978 8.05658 28.8938 8.05658 29.9978C8.05658 31.1018 8.95258 31.9978 10.0566 31.9978H22.0566C23.1606 31.9978 24.0566 31.1018 24.0566 29.9978C24.0566 28.8938 23.1606 27.9978 22.0566 27.9978H10.0566Z"
      fill="#EB7A17"
    />
  </svg>
</template>
