<template>
  <section class="section dark-bg vacancies-section">
    <div class="container">
      <div class="vacancies">
        <div class="vacancies__info">
          <h2 class="title title--invert">Стань частью<br />дружного коллектива</h2>
          <p class="vacancies__text text">
            Разрабатываем и внедряем системы для комплексной автоматизации бизнеса. Мы создаем индивидуальное программное обеспечение на
            основе задач и потребностей компании, а не перестраиваем бизнес-процессы под логику системы.
          </p>
          <nuxt-link to="https://spb.hh.ru/employer/9659698" target="_blank" class="button gradient-bg vacancies__btn">
            Смотреть вакансии
          </nuxt-link>
        </div>
        <nuxt-img src="/img/bg/team.png" format="webp" quality="80" loading="lazy" class="vacancies__img" />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.vacancies {
  display: flex;
  position: relative;
  padding: 30px 0;
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    width: 686px;
    max-width: 45%;
  }

  &__text {
    max-width: 588px;
  }

  &__img {
    position: absolute;
    bottom: -80px;
    right: 0;
    width: 823px;
    max-width: 55%;
  }
}

@media (max-width: 1024px) {
  .vacancies {
    &__img {
      object-fit: contain;
      bottom: -40px;
      object-position: 100% 100%;
      left: 50%;
      width: 617px;
      max-width: initial;
    }

    &__btn {
      padding: 16px 50px;
    }
  }
}

@media (max-width: 767px) {
  .vacancies {
    flex-direction: column;
    padding: 0;
    gap: 40px;

    &__img {
      position: static;
      margin: 0 auto;
      max-width: 100%;
    }

    &__info,
    &__text {
      max-width: 100%;
    }
  }

  .vacancies-section {
    padding-bottom: 0;
  }
}
</style>
