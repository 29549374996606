<template>
  <section class="section grey-bg">
    <div class="container">
      <div class="reviews">
        <div class="reviews__header">
          <h2 class="title">
            Вы в отличной<br />
            компании
          </h2>
        </div>
        <div class="reviews__list">
          <carousel :items-to-show="3.5" :breakpoints="breakpoints">
            <slide v-for="(item, index) in reviews" :key="`review-${index}`">
              <div :class="`reviews__item ${index === lastReviewIndex ? 'reviews__item--accent gradient-bg' : ''}`">
                <h3 class="reviews__title">
                  {{ `"${item.title}"` }}
                </h3>
                <p class="reviews__text card-text">
                  {{ item.text }}
                </p>
                <div class="reviews__author">
                  <div class="reviews__img-wrapper">
                    <img :src="item.photo" :alt="item.author" class="reviews__img" />
                  </div>
                  <div class="reviews__author-info">
                    <span class="reviews__name card-text">{{ item.author }}</span>
                    <span class="reviews__role">{{ item.role }}</span>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons>
              <Navigation />
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const reviews = [
  {
    title: 'Экспедиториум ERP',
    text: 'Специалисты TERPO проанализировали специфику нашего бизнеса (управление экспедированием, пароходством, ГТД) и воплотили в жизнь все поставленные цели ERP-системы. Можно смело сказать, что теперь наше предприятие функционирует гораздо более эффективно.',
    author: 'Bodrus',
    role: 'Лидер рынка в растаможке и перевозке грузов',
    photo: '/img/reviews/customer1.jpg',
  },
  {
    title: 'ВсеЗарядки CRM',
    text: 'В TERPO разработали для нас систему управления продажами за 2,5 месяца и, что немаловажно, за максимально разумную сумму. Рекомендуем! Продукт и подход к работе с заказчиками действительно впечатляют.',
    author: 'ВсеЗарядки',
    role: 'Специализированный магазин источников питания',
    photo: '/img/reviews/customer2.jpg',
  },
  {
    title: 'Vespa CRM',
    text: 'Сколько мы пробовали готовых решений – не счесть. В итоге поняли, что проще найти подрядчика и разработать систему под нас. Довольны как системой, так и процессом работы с TERPO.  Наконец-то CRM на 100% подходит под бизнес-задачи нашего отдела продаж. На очереди автоматизация HR. Однозначно рекомендуем.',
    author: 'Веспа',
    role: 'Торговая организация',
    photo: '/img/reviews/customer3.jpg',
  },
  {
    title: 'CRM',
    text: 'Благодарим TERPO за разработку и внедрение CRM. Для нас это настоящий прорыв в организации работы нашего предприятия. Мы теперь можем легко собирать и анализировать информацию о покупателях, что позволяет делать им персональные предложения и повышать уровень обслуживания.',
    author: 'Легкие решения',
    role: 'Торговая организация',
    photo: '/img/reviews/customer4.jpg',
  },
];

const lastReviewIndex = ref<number>(reviews.length - 1);
const breakpoints = {
  300: {
    itemsToShow: 1,
    snapAlign: 'center',
  },
  500: {
    itemsToShow: 1.5,
    snapAlign: 'start',
  },
  768: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 2.5,
    snapAlign: 'start',
  },
  1300: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
};
</script>

<style scoped lang="scss">
.reviews {
  color: var(--text-color);

  &__title {
    font-size: 22px;
    font-weight: var(--bold);
    line-height: 28px;
  }

  &__list {
    display: flex;
    position: relative;
  }

  &__item {
    background-color: var(--white);
    padding: 32px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: left;

    &--accent {
      color: var(--white);
      .card-text {
        color: var(--white);
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 36px;
  }

  &__author {
    display: flex;
    gap: 14px;
    margin-top: auto;
    align-items: center;
  }

  &__author-info {
    display: flex;
    flex-direction: column;
  }

  &__img-wrapper {
    width: 80px;
    height: 80px;
    min-width: 80px;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(92.76deg, #fe9940 0.83%, #cc3535 110.14%);
  }

  &__img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  &__name {
    font-weight: var(--bold);
  }

  &__role {
    font-size: 16px;
    line-height: 22px;
  }

  &__nav-btn {
    width: 65px;
    height: 64px;
    border: none;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel__slide {
    padding: 16px;
  }
}

@media (max-width: 767px) {
  .reviews {
    &__author {
      flex-direction: column;
      text-align: center;
    }
  }
}
</style>
