<template>
  <svg
    width="44"
    height="36"
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19L12.5 21.5858C11.7268 22.3668 11.7268 23.6332 12.5 24.4142L15 27M29 19L31.5 21.5858C32.2732 22.3668 32.2732 23.6332 31.5 24.4142L29 27M2 12V28C2 31.3137 4.68629 34 8 34H36C39.3137 34 42 31.3137 42 28V12M2 12V8C2 4.68629 4.68629 2 8 2H36C39.3137 2 42 4.68629 42 8V12M2 12H42M20 28L24 18"
      stroke="#EB7A17"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
