<template>
  <svg
    width="120"
    height="104"
    viewBox="0 0 120 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M102 62C101.47 62 100.961 61.7893 100.586 61.4142C100.211 61.0391 100 60.5304 100 60V20C100 18.4087 99.3679 16.8826 98.2426 15.7574C97.1174 14.6321 95.5913 14 94 14H82C81.4696 14 80.9609 13.7893 80.5858 13.4142C80.2107 13.0391 80 12.5304 80 12C80 11.4696 80.2107 10.9609 80.5858 10.5858C80.9609 10.2107 81.4696 10 82 10H94C96.6522 10 99.1957 11.0536 101.071 12.9289C102.946 14.8043 104 17.3478 104 20V60C104 60.5304 103.789 61.0391 103.414 61.4142C103.039 61.7893 102.53 62 102 62Z"
      fill="#FFAF76"
    />
    <path
      d="M18 62C17.4696 62 16.9609 61.7893 16.5858 61.4142C16.2107 61.0391 16 60.5304 16 60V20C16 17.3478 17.0536 14.8043 18.9289 12.9289C20.8043 11.0536 23.3478 10 26 10H38C38.5304 10 39.0391 10.2107 39.4142 10.5858C39.7893 10.9609 40 11.4696 40 12C40 12.5304 39.7893 13.0391 39.4142 13.4142C39.0391 13.7893 38.5304 14 38 14H26C24.4087 14 22.8826 14.6321 21.7574 15.7574C20.6321 16.8826 20 18.4087 20 20V60C20 60.5304 19.7893 61.0391 19.4142 61.4142C19.0391 61.7893 18.5304 62 18 62Z"
      fill="#FFAF76"
    />
    <path
      d="M82 4V24H38V4C38 2.93913 38.4214 1.92172 39.1716 1.17157C39.9217 0.421427 40.9391 0 42 0H78C79.0609 0 80.0783 0.421427 80.8284 1.17157C81.5786 1.92172 82 2.93913 82 4Z"
      fill="url(#paint0_linear_1_878)"
    />
    <path
      d="M82 24V28C82 29.0609 81.5786 30.0783 80.8284 30.8284C80.0783 31.5786 79.0609 32 78 32H42C40.9391 32 39.9217 31.5786 39.1716 30.8284C38.4214 30.0783 38 29.0609 38 28V24H82Z"
      fill="url(#paint1_linear_1_878)"
    />
    <path
      d="M120 84V88C120 89.0609 119.579 90.0783 118.828 90.8284C118.078 91.5786 117.061 92 116 92H80C78.9391 92 77.9217 91.5786 77.1716 90.8284C76.4214 90.0783 76 89.0609 76 88V84H120Z"
      fill="url(#paint2_linear_1_878)"
    />
    <path
      d="M120 64V84H76V64C76 62.9391 76.4214 61.9217 77.1716 61.1716C77.9217 60.4214 78.9391 60 80 60H116C117.061 60 118.078 60.4214 118.828 61.1716C119.579 61.9217 120 62.9391 120 64Z"
      fill="url(#paint3_linear_1_878)"
    />
    <path
      d="M44 64V84H0V64C0 62.9391 0.421427 61.9217 1.17157 61.1716C1.92172 60.4214 2.93913 60 4 60H40C41.0609 60 42.0783 60.4214 42.8284 61.1716C43.5786 61.9217 44 62.9391 44 64Z"
      fill="url(#paint4_linear_1_878)"
    />
    <path
      d="M44 84V88C44 89.0609 43.5786 90.0783 42.8284 90.8284C42.0783 91.5786 41.0609 92 40 92H4C2.93913 92 1.92172 91.5786 1.17157 90.8284C0.421427 90.0783 0 89.0609 0 88V84H44Z"
      fill="url(#paint5_linear_1_878)"
    />
    <path d="M70 44H50L54 32H66L70 44Z" fill="#F17B31" />
    <path
      d="M70 44H50C49.4696 44 48.9609 43.7893 48.5858 43.4142C48.2107 43.0391 48 42.5304 48 42C48 41.4696 48.2107 40.9609 48.5858 40.5858C48.9609 40.2107 49.4696 40 50 40H70C70.5304 40 71.0391 40.2107 71.4142 40.5858C71.7893 40.9609 72 41.4696 72 42C72 42.5304 71.7893 43.0391 71.4142 43.4142C71.0391 43.7893 70.5304 44 70 44Z"
      fill="url(#paint6_linear_1_878)"
    />
    <path d="M32 104H12L16 92H28L32 104Z" fill="#F17B31" />
    <path
      d="M32 104H12C11.4696 104 10.9609 103.789 10.5858 103.414C10.2107 103.039 10 102.53 10 102C10 101.47 10.2107 100.961 10.5858 100.586C10.9609 100.211 11.4696 100 12 100H32C32.5304 100 33.0391 100.211 33.4142 100.586C33.7893 100.961 34 101.47 34 102C34 102.53 33.7893 103.039 33.4142 103.414C33.0391 103.789 32.5304 104 32 104Z"
      fill="url(#paint7_linear_1_878)"
    />
    <path d="M108 104H88L92 92H104L108 104Z" fill="#F17B31" />
    <path
      d="M108 104H88C87.4696 104 86.9609 103.789 86.5858 103.414C86.2107 103.039 86 102.53 86 102C86 101.47 86.2107 100.961 86.5858 100.586C86.9609 100.211 87.4696 100 88 100H108C108.53 100 109.039 100.211 109.414 100.586C109.789 100.961 110 101.47 110 102C110 102.53 109.789 103.039 109.414 103.414C109.039 103.789 108.53 104 108 104Z"
      fill="url(#paint8_linear_1_878)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_878"
        x1="38"
        y1="12"
        x2="82"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5DFD3" />
        <stop offset="1" stop-color="#FFF2EF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_878"
        x1="46.4779"
        y1="38.1657"
        x2="65.1913"
        y2="12.4423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_878"
        x1="84.4779"
        y1="98.1657"
        x2="103.191"
        y2="72.4423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_878"
        x1="76"
        y1="72"
        x2="120"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5DFD3" />
        <stop offset="1" stop-color="#FFF2EF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_878"
        x1="0"
        y1="72"
        x2="44"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5DFD3" />
        <stop offset="1" stop-color="#FFF2EF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_878"
        x1="8.47786"
        y1="98.1657"
        x2="27.1913"
        y2="72.4423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FEC28A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_878"
        x1="53"
        y1="46"
        x2="70.8935"
        y2="32.5444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FFCEA1" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1_878"
        x1="15"
        y1="106"
        x2="32.8935"
        y2="92.5444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FFCEA1" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1_878"
        x1="91"
        y1="106"
        x2="108.893"
        y2="92.5444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9254" />
        <stop offset="1" stop-color="#FFCEA1" />
      </linearGradient>
    </defs>
  </svg>
</template>
